import axios from 'axios'

import {
  formatResponse,
  formatItemResponse,
  formatListResponse
} from '@/utils/api'

const BASE_URL = `/api/team-group`
const URLs = new Proxy(
  {
    FETCH_ALL_BY_TEAM: `/get-for-team/`,

    ADD_MEMBERS: `/add-members`,
    // REPLACE_MEMBERS: `/replace-members`,
    REMOVE_MEMBERS: `/remove-members`,

    CREATE_ONE: `/create`,
    UPDATE_ONE: `/update`,
    DELETE_ONE: `/delete/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllByTeam(teamId) {
    const res = await axios.get(`${URLs.FETCH_ALL_BY_TEAM}${teamId}`)

    return formatListResponse(res)
  },
  async addMembers(payload) {
    const res = await axios.put(URLs.ADD_MEMBERS, payload)

    return formatItemResponse(res)
  },
  // async replaceMembers(payload) {
  //   const res = await axios.put(URLs.REPLACE_MEMBERS, payload)

  //   return formatItemResponse(res)
  // },
  async removeMembers(payload) {
    const res = await axios.put(URLs.REMOVE_MEMBERS, payload)

    return formatItemResponse(res)
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatResponse(res)
  }
}

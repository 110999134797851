import $api from '@/api'

import * as notify from '@/utils/notify'

import { paToA, defineRuledList } from '@/utils/attributes'

export default {
  namespaced: true,

  state: {
    principalAttributes: {
      list: [],
      best: []
    }
  },

  getters: {
    groupedByCategory: () => list => {
      const byCategoryId = list.reduce((result, pa) => {
        const category = pa.attribute.attributeCategory || pa.attributeCategory
        const prev = result[category.id]?.principalAttributes || []

        result[category.id] = {
          ...pa,
          category,
          principalAttributes: [...prev, pa]
        }

        return result
      }, {})

      return Object.values(byCategoryId)
    },

    ruledPa: state => (principal, rules) => {
      const { list, best } = state.principalAttributes

      const attributesList = list.map(paToA)
      const attributesBest = best.map(paToA)

      const ruledAttributesList = defineRuledList({
        principal,
        rules,
        list: attributesList
      })
      const ruledAttributesBest = defineRuledList({
        principal,
        rules,
        list: attributesBest
      })

      const ruledPaList = list.filter(pa =>
        ruledAttributesList.map(item => item.id).includes(pa.attribute.id)
      )
      const ruledPaBest = best.filter(pa =>
        ruledAttributesBest.map(item => item.id).includes(pa.attribute.id)
      )

      return {
        list: ruledPaList,
        best: ruledPaBest
      }
    }
  },

  actions: {
    async FETCH_ALL_LIST({ commit }, { principalId, userTypeCode }) {
      try {
        const { list, error } =
          await $api.principalAttributes.fetchAllByPrincipal({
            principalId,
            userTypeCode
          })

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_PRINCIPAL_ATTRIBUTES_LIST', list)
      } catch (error) {
        notify.error({ text: error })
      }
    },

    async FETCH_ALL_BEST({ commit }, { principalId, userTypeCode }) {
      try {
        const { list, error } = await $api.principalAttributes.fetchAllBest({
          principalId,
          userTypeCode
        })

        if (error) {
          notify.error({ text: error })
          return
        }

        await commit('SET_PRINCIPAL_ATTRIBUTES_BEST', list)
      } catch (error) {
        notify.error({ text: error })
      }
    },

    async SAVE_MULTIPLE_BEST(_, payload) {
      let result = {}

      try {
        result = await $api.principalAttributes.setMultipleBest(payload)

        const { error } = result

        if (error) {
          notify.error({ text: error })
          return
        }
      } catch (error) {
        notify.error({ text: error })
      }

      return result.isSuccess
    },

    async ADD_MULTIPLE_BY_PRINCIPAL_AND_CATEGORY(_, payload) {
      try {
        const result =
          await $api.principalAttributes.addMultipleByPrincipalAndCategory(
            payload
          )

        return result.isSuccess
      } catch (error) {
        notify.error({ text: error })
      }
    },

    async DELETE_MULTIPLE_BY_PRINCIPAL(_, payload) {
      try {
        const result =
          await $api.principalAttributes.deleteMultipleByPrincipal(payload)

        return result.isSuccess
      } catch (error) {
        notify.error({ text: error })
      }
    }
  },

  mutations: {
    SET_PRINCIPAL_ATTRIBUTES_LIST(state, list) {
      state.principalAttributes.list = list
    },

    SET_PRINCIPAL_ATTRIBUTES_BEST(state, list) {
      state.principalAttributes.best = list
    }
  }
}

<template>
  <div class="h-full p-16 gap-16 flex flex-col bg-white"></div>
</template>

<script>
// import { mapState } from 'vuex'

export default {
  name: 'PublicTestPage',

  // components: { TheBudget, PrincipalPayouts },

  data: () => ({
    // form: {
    //   name: ''
    // }
    // hello: []
  }),

  computed: {
    // ...mapState('tenants', ['tenants'])
  }
}
</script>

import $api from '@/api'
import * as notify from '@/utils/notify'

export const fetchAllViews = async () => {
  let result = {}

  try {
    result = await $api.teams.fetchAllViews()
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const fetchAllByIds = async payload => {
  let result = {}

  try {
    result = await $api.teams.fetchAllByIds(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

// export const fetchAllByKeyword = async payload => {
//   let result = {}

//   try {
//     result = await $api.teams.fetchAllByKeyword(payload)
//     const { error } = result

//     if (error) {
//       notify.error({ text: error })
//     }
//   } catch (error) {
//     notify.error({ text: error })
//   }

//   return result
// }

export const fetchHighlights = async payload => {
  let result = {}

  try {
    result = await $api.teams.fetchHighlights(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const addAllUsersToTeam = async payload => {
  let result = {}

  try {
    result = await $api.teams.addAllUsersToTeam(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const syncToHubspot = async ({ id }) => {
  let result = {}

  try {
    result = await $api.teams.syncToHubspot({ id })
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const syncManyToHubspot = async idList => {
  const promises = idList.map(id => syncToHubspot({ id }))

  const result = await Promise.all(promises)

  const isSuccess = result.every(item => item.isSuccess)

  return { isSuccess }
}

export const createOne = async payload => {
  let result = {}

  try {
    result = await $api.teams.createOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const updateOne = async payload => {
  let result = {}

  try {
    result = await $api.teams.updateOne(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

export const deleteMultiple = async payload => {
  let result = {}

  try {
    result = await $api.teams.deleteMultiple(payload)
    const { error } = result

    if (error) {
      notify.error({ text: error })
    }
  } catch (error) {
    notify.error({ text: error })
  }

  return result
}

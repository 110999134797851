import catalog from '@/store/modules/admin/teams/catalog'
// registered dynamically in the Teams/TheProfile.vue
// import profile from '@/store/modules/admin/teams/profile'
import ui from '@/store/modules/admin/teams/ui'

export default {
  namespaced: true,

  modules: { catalog, ui }
}

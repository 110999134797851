import dayjs from 'dayjs'
import upperFirst from 'lodash/upperFirst'

import { defineIsEmbedVideoLink } from '@/utils/video-link'

export const BASE_DURATION = 5000
export const BASE_LIMIT = 25

export const PDF_VIEWER_MENU_Z_INDEX = 1001

export const defineIsEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export const secondsToDays = seconds =>
  seconds ? parseInt(seconds / 24 / 60 / 60) : 0
export const secondsToHours = seconds => secondsToDays(seconds) * 24

export const daysToSeconds = days => days * 24 * 60 * 60

export const formatKeysToPascalCase = obj =>
  Object.keys(obj).reduce(
    (result, key) => ({ ...result, [upperFirst(key)]: obj[key] }),
    {}
  )

export const parseSeconds = value => {
  const ms = value * 1000
  const duration = dayjs.duration(ms)

  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  return { days, hours, minutes, seconds }
}

export const makeHumanReadable = (number, label) => {
  if (!number) return ''

  return `${number} ${label}${number === 1 ? '' : 's'}`
}

export const secondsToDaysHours = value => {
  let { days, hours, minutes } = parseSeconds(value)

  if (minutes >= 30) hours += 1

  return [makeHumanReadable(days, 'day'), makeHumanReadable(hours, 'hour')]
    .filter(Boolean)
    .join(', ')
}

export const secondsToDaysHoursMinutesSeconds = value => {
  let { days, hours, minutes, seconds } = parseSeconds(value)

  return [
    makeHumanReadable(days, 'day'),
    makeHumanReadable(hours, 'hour'),
    makeHumanReadable(minutes, 'minute'),
    !days && !hours && !minutes && seconds
      ? makeHumanReadable(seconds, 'second')
      : ''
  ]
    .filter(Boolean)
    .join(', ')
}

export const generateUUID = () => {
  let d = new Date().getTime()
  let d2 = (performance && performance.now && performance.now() * 1000) || 0

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16

    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
  })
}

//This is the Fisher-Yates random sort algorithm
export const randomSort = myArray => {
  if (!myArray) {
    return myArray
  }

  let i = myArray.length

  if (i === 0) {
    return myArray
  }

  while (--i) {
    const j = Math.floor(Math.random() * (i + 1))
    const k = myArray[i]
    myArray[i] = myArray[j]
    myArray[j] = k
  }

  return myArray
}

export const defineFileNameByResponse = response => {
  let result = 'Unknown.dat'

  if (!response?.headers?.['content-disposition']) {
    return result
  }

  const cdHeader = response.headers['content-disposition']
  const match = cdHeader.match(/filename=([^;]+?);/i)

  if (match && match.length > 0) {
    result = match[1]
  }

  return result.replaceAll('"', '')
}

export const watchForElement = selector => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}

export const isNullOrEmpty = input =>
  !input ||
  input.length === 0 ||
  input === 'null' ||
  input === 'undefined' ||
  typeof input === 'undefined'

const defineFileExtension = file => {
  const defineIsHasExtension = str =>
    typeof str === 'string' && /\.\w+$/.test(str)

  for (const [, value] of Object.entries(file)) {
    if (defineIsHasExtension(value)) {
      return value
    }
  }

  return ''
}

export const defineFileType = file => {
  const extension = defineFileExtension({
    name: file.name,
    originalName: file.originalName,
    path: file.path,
    normalizedPath: file.normalizedPath
  })

  const isImage = extension.match(/\.(jpe?g|png|gif|bmp|svg|apng|ico|webp)$/i)
  const isPdf = extension.match(/\.(pdf)$/i)
  const isVideoFile = extension.match(/\.(mov|qt|mp4|3gp|webm|mkv|ts)$/i)
  const isVideoLink = defineIsEmbedVideoLink(extension)
  const isVideo = isVideoFile || isVideoLink
  const isPlaceholder = extension.includes('placeholder')
  const isUnknown = [isImage, isPdf, isVideo].every(v => !v)

  return {
    isImage,
    isPdf,
    isVideo,
    isVideoFile,
    isVideoLink,
    isPlaceholder,
    isUnknown
  }
}

export const generateConnection = (obj1, obj2) => {
  const obj1ByObj2 = {}

  for (const key2 in obj2) {
    const value1 = obj1[key2]

    if (value1 !== undefined) {
      obj1ByObj2[obj2[key2]] = value1 || ''
    }
  }

  return Object.freeze(obj1ByObj2)
}

export const defineAbbr = text => text.match(/[A-Z]/g).join('')

export const move = (arr, from, to) => {
  if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) return arr
  const el = arr.splice(from, 1)[0]
  arr.splice(to, 0, el)
  return arr
}

export const formatCurrency = num => {
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num)

  return formattedNumber
}

export const defineNewPosition = ({ newIndex, newList, key }) => {
  if (newIndex === 0) {
    return newList[1]?.[key] / 2
  }

  if (newIndex === newList.length - 1) {
    return newList.at(-2)?.[key] + 0.5
  }

  const prevItem = newList[newIndex - 1]
  const nextItem = newList[newIndex + 1]

  return (prevItem?.[key] + nextItem?.[key]) / 2
}

import * as services from '@/services'
import { defineIsEqual } from '@/utils/base'

const defaultFilters = Object.freeze({
  teamName: '',
  isSubteams: null,
  isMembers: null,

  orderBy: 'rankNumber',
  isSortAscending: true
})

const applyFilters = (list, filters) => {
  const { teamName, isSubteams, isMembers } = filters

  let filteredList = structuredClone(list)

  if (teamName) {
    filteredList = filteredList.filter(item =>
      item.name.toLowerCase().includes(teamName.toLowerCase())
    )
  }

  if (isSubteams !== null) {
    filteredList = filteredList.filter(item =>
      isSubteams ? item.groupCount : !item.groupCount
    )
  }

  if (isMembers !== null) {
    filteredList = filteredList.filter(item =>
      isMembers ? item.memberCount : !item.memberCount
    )
  }

  return filteredList
}

const applySorting = (list, filters) => {
  const { orderBy, isSortAscending } = filters

  let sortedList = structuredClone(list)

  if (orderBy === 'name') {
    sortedList.sort((a, b) => {
      return isSortAscending
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name)
    })
  }

  if (orderBy === 'rankNumber') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.rankNumber - b.rankNumber
        : b.rankNumber - a.rankNumber
    )
  }
  if (orderBy === 'totalSubteams') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.groupCount - b.groupCount
        : b.groupCount - a.groupCount
    )
  }

  if (orderBy === 'totalMembers') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? a.memberCount - b.memberCount
        : b.memberCount - a.memberCount
    )
  }

  if (orderBy === 'createdAt') {
    sortedList.sort((a, b) =>
      isSortAscending
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt)
    )
  }

  return sortedList
}

export default {
  namespaced: true,

  state: {
    catalog: {
      isLoading: false,

      rows: {
        initialList: [],
        list: [],
        selected: []
      },

      focusAreas: {
        list: []
      },

      filters: structuredClone(defaultFilters),
      defaultFilters: structuredClone(defaultFilters),

      ui: {
        createTeamDialog: { isVisible: false, focusArea: null },
        createFocusAreaDialog: { isVisible: false, focusArea: null },

        focusAreaExpandedStates: {}
      }
    }
  },

  getters: {
    focusAreaList: (state, _, rootState) => {
      const focusAreaList = [
        ...state.catalog.focusAreas.list,
        { id: null, name: 'Custom' }
      ]

      return focusAreaList.map(fa => {
        let teamList = fa.id
          ? state.catalog.rows.list.filter(item => item.focusAreaId === fa.id)
          : state.catalog.rows.list.filter(item => !item.focusAreaId)

        teamList = teamList.filter(item =>
          item.isPrivate ? item.privateUserId === rootState.user.user.id : true
        )

        return { id: fa.id, name: fa.name, teamList }
      })
    }
  },

  actions: {
    async FETCH_ALL({ commit, state }) {
      const filters = state.catalog.filters

      await Promise.all([
        commit('SET_IS_LOADING', true),
        commit('SET_DEFAULT_FILTERS', filters),
        commit('SET_ROWS_INITIAL_LIST', []),
        commit('SET_ROWS_LIST', []),
        commit('SET_ROWS_SELECTED', []),
        commit('SET_FOCUS_AREA_LIST', [])
      ])

      const results = await Promise.all([
        services.focusAreas.fetchAll(),
        services.teams.fetchAllViews()
      ])

      let [{ list: focusAreaList }, { list: teamsList }] = results

      teamsList = teamsList.map((item, index) => {
        item.rankNumber = item.rankNumber || index + 1

        return item
      })

      teamsList = applyFilters(teamsList, filters)
      teamsList = applySorting(teamsList, filters)

      await Promise.all([
        commit('SET_ROWS_INITIAL_LIST', teamsList),
        commit('SET_ROWS_LIST', teamsList),
        commit('SET_FOCUS_AREA_LIST', focusAreaList),
        commit('SET_IS_LOADING', false)
      ])
    },

    async APPLY_FILTERS({ commit, state }) {
      const filters = state.catalog.filters

      const isFiltersWithoutSortingModified = !defineIsEqual(
        { ...filters, orderBy: undefined, isSortAscending: undefined },
        {
          ...state.catalog.defaultFilters,
          orderBy: undefined,
          isSortAscending: undefined
        }
      )

      await Promise.all([
        isFiltersWithoutSortingModified &&
          commit('SET_UI_SUBTEAM_EXPANDED_STATES', {}),
        commit('SET_DEFAULT_FILTERS', filters),
        commit('SET_ROWS_SELECTED', [])
      ])

      let list = state.catalog.rows.initialList

      list = applyFilters(list, filters)
      list = applySorting(list, filters)

      await Promise.all([commit('SET_ROWS_LIST', list)])
    }
  },

  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.catalog.isLoading = isLoading
    },

    SET_ROWS_INITIAL_LIST(state, list) {
      state.catalog.rows.initialList = list
    },
    SET_ROWS_LIST(state, list) {
      state.catalog.rows.list = list
    },
    SET_ROWS_SELECTED(state, selected) {
      state.catalog.rows.selected = selected
    },

    SET_FOCUS_AREA_LIST(state, list) {
      state.catalog.focusAreas.list = list
    },

    UPDATE_FILTERS(state, toUpdate) {
      state.catalog.filters = { ...state.catalog.filters, ...toUpdate }
    },

    SET_FILTERS(state, filters) {
      state.catalog.filters = filters
    },
    RESET_FILTERS(state) {
      state.catalog.filters = structuredClone(defaultFilters)
      state.catalog.defaultFilters = structuredClone(defaultFilters)
    },

    SET_DEFAULT_FILTERS(state, defaultFilters) {
      state.catalog.defaultFilters = structuredClone(defaultFilters)
    },

    SET_FILTERS_ORDER_BY(state, orderBy) {
      state.catalog.filters.orderBy = orderBy
    },
    SET_FILTERS_IS_SORT_ASCENDING(state, isSortAscending) {
      state.catalog.filters.isSortAscending = isSortAscending
    },

    UPDATE_UI(state, toUpdate) {
      state.catalog.ui = { ...state.catalog.ui, ...toUpdate }
    },

    SET_UI_FOCUS_AREA_EXPANDED_STATES(state, states) {
      state.catalog.ui.focusAreaExpandedStates = states
    },
    UPDATE_UI_FOCUS_AREA_EXPANDED_STATES(state, toUpdate) {
      state.catalog.ui.focusAreaExpandedStates = {
        ...state.catalog.ui.focusAreaExpandedStates,
        ...toUpdate
      }
    }
  }
}
